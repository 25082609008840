import React from "react"
import { Container, Box, Grid, Typography } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function RequisicoesPage(props) {
  const classes = useStyles();


  return (
    <Layout>
      <SEO title="Requisições" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Requisições</PageTitle>
        <Grid container style={{ marginTop: 70 }}>
          <Grid xs={6} sm={6} justify="center" align="center">
            <a href="https://pag.ae/7WfT_Fj17" target="_blank">
              <AccountBoxIcon style={{ fontSize: "7.1875rem" }} />
              <Typography>Carteira Estudantil</Typography>
            </a>
          </Grid>
          <Grid xs={6} sm={6} justify="center" align="center">
            <a href="https://pag.ae/7Wj5FLUVs" target="_blank">
              <DescriptionIcon style={{ fontSize: "7.1875rem" }} />
              <Typography>Plano de Ensino do Aluno</Typography>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
